import { Button, IconKeys } from '@groupbuilderoy/gb-components-library';
import { useEditingMode } from 'hooks/useEditingMode';
import styles from './styles';
import useT from 'hooks/useT';
interface EditableContentProps {
  editCaption: string;
  iconKey?: IconKeys;
  children: React.ReactNode;
  onEdit?: () => void;
  hasPhase?: boolean;
  onCreatePhase?: () => void;
  onEditPhase?: () => void;
  onCreateHeaderSection?: () => void;
}
export default function EditableContent({
  editCaption,
  iconKey,
  children,
  onEdit,
  hasPhase,
  onCreatePhase,
  onEditPhase,
  onCreateHeaderSection
}: EditableContentProps) {
  const { editingModeEnabled } = useEditingMode();
  const t = useT();
  const classes = styles();
  if (!editingModeEnabled || !onEdit) return <>{children}</>;
  return (
    <>
      <div className={classes.buttonContainer}>
        <div className={classes.leftButtons}>
          {hasPhase && (
            <>
              {onCreatePhase && (
                <Button
                  caption={t('form.phase.newPhase')}
                  onClick={onCreatePhase}
                  startIcon={iconKey}
                />
              )}
              {onEditPhase && (
                <Button
                  caption={t('form.phase.editPhase')}
                  onClick={onEditPhase}
                  startIcon={IconKeys.edit}
                />
              )}
            </>
          )}
        </div>
        <div className={classes.rightButtons}>
          {hasPhase && (
            <Button
              caption={t('form.phase.newHeaderSection')}
              onClick={onCreateHeaderSection}
              startIcon={iconKey}
            />
          )}
          <Button caption={editCaption} onClick={onEdit} startIcon={iconKey} />
        </div>
      </div>
      {children}
    </>
  );
}
