import { EventType } from '../../interfaces';
import QuestionField from './QuestionField';

interface EventQuestionsProps {
  event: EventType;
  handleChange: any;
  answer1: string;
  answer2: string;
}

const EventQuestions = ({ event, handleChange, answer1, answer2 }: EventQuestionsProps) => {
  return (
    <>
      {event.question1 && (
        <QuestionField
          question={event.question1}
          answer={answer1}
          handleChangeAnswer={handleChange}
          fieldName='answer1'
          isRequired={false}
          inputProps={{
            style: {
              height: '80px',
              paddingTop: '10px',
              paddingLeft: '10px',
              alignItems: 'flex-start',
              fontSize: '0.875rem'
            }
          }}
        />
      )}
      {event.question2 && (
        <QuestionField
          question={event.question2}
          answer={answer2}
          handleChangeAnswer={handleChange}
          fieldName='answer2'
          isRequired={false}
          inputProps={{
            style: {
              height: '80px',
              paddingTop: '10px',
              paddingLeft: '10px',
              alignItems: 'flex-start',
              fontSize: '0.875rem'
            }
          }}
        />
      )}
    </>
  );
};

export default EventQuestions;
