import Header from './components/Header/Header';
import { Container } from '@material-ui/core';
import Messaging from 'routes/Messaging/Messaging';
import Footer from 'components/Footer/Footer';
import useSetupApp from 'hooks/useSetupApp';
import useSetupProjects from 'hooks/useSetupProjects';
import useSetupApartments from 'hooks/useSetupApartments';
import { Route, useLocation } from 'react-router-dom';
import { MessagingPaths } from '@groupbuilderoy/messaging-client';
import Home from 'routes/Home/Home';
import { useContext } from 'react';
import { AppContext } from 'context/App/AppContext';
import { Alert } from '@material-ui/lab';
import Progress from 'components/Progress/Progress';
import SectionContextProvider from 'context/Section/SectionContextProvider';
import PhaseContextProvider from 'context/Phase/PhaseContextProvider';
import { useStyles } from './styles';
import AttachmentViewer from 'components/Attachments/AttachmentViewer/AttachmentViewer';
import ThreeD from 'routes/3D/3D';
import ModalMessage from 'components/ModalMessage/ModalMessage';
import CJ2 from 'routes/CJ2/CJ2';
import { ProjectContext } from 'context/Project/ProjectContext';
import { integratedAppPaths } from 'utils/utils';
import useSetupFavIcon from 'hooks/useSetupFavIcon';
import FormsContextProvider from 'context/Forms/FormsContextProvider';
import CalendarEventsContextProvider from 'context/CalendarEvents/CalendarEventsContextProvider';
import UserDetails from 'routes/UserDetails/UserDetails';
import ApartmentOwners from 'routes/ApartmentOwners/ApartmentOwners';
import OwnerSearch from 'routes/OwnerSearch/OwnerSearch';
import FooterHartela from 'components/FooterHartela/FooterHartela';
import { UserRight } from 'context/App/types';
import useSetupPhases from 'hooks/useSetupPhases';

function App() {
  useSetupApp();
  useSetupProjects();
  useSetupApartments();
  useSetupFavIcon();
  useSetupPhases();

  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { loading, error } = appState;
  const classes = useStyles({
    backgroundColor: appState.tenantSettings?.themeCustomizationSettings?.palette.backgroundColor,
    backgroundImg: appState.tenantSettings?.themeCustomizationSettings?.assets.backgroundImg
  })();
  const location = useLocation();
  const orgId = appState.tenantSettings?.organisationId;

  if (loading) return <Progress />;
  else if (error) {
    return (
      <Alert className={classes.alert} variant='outlined' severity='error'>
        {error}
      </Alert>
    );
  }

  return (
    <div className={classes.body}>
      <PhaseContextProvider>
        <SectionContextProvider>
          <ModalMessage />
          <AttachmentViewer />
          <Header />
          <Container
            role='main'
            className={
              integratedAppPaths.includes(location.pathname)
                ? location.pathname === '/selections'
                  ? classes.selectionsFrameContainer
                  : classes.iframeContainer
                : classes.container
            }
          >
            <Route
              exact
              path={[MessagingPaths.ApartmentMessages, MessagingPaths.Bulletins]}
              component={Messaging}
            />
            <Route exact path='/userdetails' component={UserDetails} />
            {projectState.userRights?.check(UserRight.userSearch) && (
              <>
                <Route exact path='/ownersDetails' component={ApartmentOwners} />
                <Route exact path='/ownersearch' component={OwnerSearch} />
              </>
            )}
            <Route exact path='/3D' component={ThreeD} />
            {projectState.projectSettings?.['cj2IntegratedIntoCj3'] && (
              <Route exact path='/selections' component={CJ2} />
            )}
            <Route
              path={[
                '/',
                '/project/:projectId/apartment/:apartmentId/phase/:phaseId/section/:sectionId/edit',
                '/project/:projectId/apartment/:apartmentId/phase/:phaseId/section/:sectionId',
                '/project/:projectId/apartment/:apartmentId/phase/:phaseId',
                '/project/:projectId/apartment/:apartmentId/phase/:phaseId/create',
                '/project/:projectId/apartment/:apartmentId/phase/:phaseId/edit',
                '/project/:projectId/apartment/:apartmentId',
                '/project/:projectId',
                '/project/:projectId/apartment/:apartmentId/phase/:phaseId/section/:sectionId/*'
              ]}
              exact
            >
              <FormsContextProvider>
                <CalendarEventsContextProvider>
                  <Home />
                </CalendarEventsContextProvider>
              </FormsContextProvider>
            </Route>
          </Container>
          {location.pathname !== '/selections' && (
            <>{orgId === 'hartela' ? <FooterHartela /> : <Footer />}</>
          )}
        </SectionContextProvider>
      </PhaseContextProvider>
    </div>
  );
}

export default App;
