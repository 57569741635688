import React from 'react';
import { Grid, InputLabel, Select, FormControl, OutlinedInput } from '@material-ui/core';
interface CalendarEventSlotSelectorProps {
  title: string;
  value: string | undefined;
  selectHandling: any;
  children: React.ReactNode;
}
const CalendarEventSlotSelector = ({
  title,
  value,
  selectHandling,
  children
}: CalendarEventSlotSelectorProps) => {
  return (
    <Grid item xs={12} style={{ marginBottom: '10px', marginTop: '10px' }}>
      <FormControl variant='outlined' fullWidth>
        <InputLabel style={{ color: 'black' }}>{title}</InputLabel>
        <Select
          input={<OutlinedInput style={{ fontSize: '0.875rem' }} label='Tag' />}
          label={title}
          name={title}
          variant='outlined'
          fullWidth
          value={value}
          onChange={(e) => selectHandling(e.target.value as string)}
        >
          {children}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default CalendarEventSlotSelector;
