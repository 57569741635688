import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = ({ freeContent }: any) => {
  return makeStyles(() =>
    createStyles({
      tile: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...(freeContent
          ? {
              background: 'none !important',
              boxShadow: 'none !important',
              border: 'none !important',
              padding: '0 !important'
            }
          : {}),

        '& button': {
          marginTop: 'auto'
        },

        '& .tag': {
          right: '-32px',

          '&::before': {
            content: '""'
          }
        }
      }
    })
  );
};
