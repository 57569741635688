import useBasePath from 'hooks/useBasePath';
import useT from 'hooks/useT';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { useStyles } from './styles';
import { PhaseContext } from 'context/Phase/PhaseContext';
import { SectionContext } from 'context/Section/SectionContext';
import Section, { ISection } from '../Section';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import { Card } from '@groupbuilderoy/gb-components-library';
import { ProjectContext } from 'context/Project/ProjectContext';
import useApi from 'hooks/useApi';
import {
  SectionWithContent,
  calculateNewOrderNumber,
  createSectionsWithContent
} from 'utils/sections';
import { ModalMessageContext } from 'context/ModalMessage/ModalMessageContext';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import FlashNotification from 'components/FlashNotification/FlashNotification';
import { useTrackers } from 'hooks/useTrackers';
import { EventAction } from 'services/EventAction';
import { EventCategory } from 'services/EventCategory';

interface SectionCardProps extends React.HTMLProps<HTMLDivElement> {
  section: Section;
  loading?: boolean;
  error?: boolean;
  noActionButton?: boolean;
  onActionButtonClick?: () => void;
  autoHeight?: boolean;
}

export default function SectionCard({
  section,
  children,
  loading,
  error,
  noActionButton,
  onActionButtonClick,
  autoHeight = false
}: SectionCardProps) {
  const { triggerEvent } = useTrackers();
  const api = useApi();
  const t = useT();
  const { id, name, description, actionButtonTxtKey } = section;
  const { projectState } = useContext(ProjectContext);
  const { phaseState } = useContext(PhaseContext);
  const { sectionState, dispatch } = useContext(SectionContext);
  const { modalMessageState, dispatch: dispatchModalMessage } = useContext(ModalMessageContext);
  const history = useHistory();
  const basePath = useBasePath();
  const [changingOrder, setChangingOrder] = useState<boolean>(false);
  const [deleteFailed, setDeleteFailed] = useState<boolean>(false);
  const [updateOrderFailed, setUpdateOrderFailed] = useState<boolean>(false);

  // const classes = useStyles({freeContent: section.freeContent});
  const classes = useStyles({
    //TODO: implement freeContent based on section attribute when backend support is ready
    freeContent: [''].includes(section.name?.replace(' ', ''))
  })();

  const sectionUrl = `${basePath}/phase/${phaseState.selectedPhase?.id}/section/${id}`;

  const onButtonClick = () => {
    if (onActionButtonClick) onActionButtonClick();
    else {
      dispatch({
        ...sectionState,
        showBackButton: true
      });
      history.push(sectionUrl);
    }
    triggerEvent({
      action: EventAction.SECTION_CLICKED,
      category: EventCategory.USER
    });
  };

  const refetchSections = async () => {
    if (phaseState.selectedPhase?.id) {
      const newSections = await api.getAllSections(phaseState.selectedPhase?.id);
      dispatch({
        ...sectionState,
        sections: createSectionsWithContent(newSections as ISection[]) as SectionWithContent[]
      });
    }
  };

  const refetchSectionsAfterDelete = async (deletedSectionId: number) => {
    dispatch({
      ...sectionState,
      loading: false,
      update: false,
      sections: sectionState.sections.filter((s) => s.id !== deletedSectionId)
    });
  };

  const handleChangeSectionOrder = async (newOrder: string) => {
    try {
      if (!section.parentSectionId) {
        setChangingOrder(true);
        await api.updateSectionOrder(
          section.id,
          calculateNewOrderNumber(newOrder, section.orderNumber, sectionState.sections.length)
        );
        await refetchSections();
      }
    } catch (e: any) {
      console.error(`Failed to update section order, error: ${e.message}`);
      setUpdateOrderFailed(true);
    } finally {
      setChangingOrder(false);
    }
  };

  const handleDeleteSection = async () => {
    try {
      const deletedSectionId = section.id;
      await api.deleteSection(section.id);
      await refetchSectionsAfterDelete(deletedSectionId);
    } catch (e: any) {
      console.error(`Failed to delete section, error: ${e.message}`);
      setDeleteFailed(true);
    } finally {
      dispatchModalMessage({
        ...modalMessageState,
        isOpen: false
      });
    }
  };

  const closeFlashNotification = () => {
    if (updateOrderFailed) setUpdateOrderFailed(false);
    else setDeleteFailed(false);
  };

  return (
    <>
      <FlashNotification
        open={updateOrderFailed || deleteFailed}
        message={
          updateOrderFailed ? t('alert.sectionOrderUpdateFailed') : t('alert.sectionDeleteFailed')
        }
        onClose={closeFlashNotification}
      />
      <Card
        adminActions={projectState.editingModeEnabled}
        disableActions={changingOrder}
        onEdit={() => {
          const editPath = `${basePath}/phase/${section?.phaseId}/section/${section?.id}/edit`;
          history.push(editPath);
        }}
        onChangeOrder={
          !sectionState.sections.find((s) => s.id === section.id)?.parentSectionId &&
          sectionState.sections.length > 1
            ? handleChangeSectionOrder
            : undefined
        }
        onDelete={() => {
          dispatchModalMessage({
            icon: <ReportProblemOutlinedIcon />,
            title: t('modal.deleteSectionCaption'),
            description: t('modal.deleteSectionDescription'),
            isOpen: true,
            onApprove: handleDeleteSection
          });
        }}
        editable={true}
        className={classes.tile}
        title={name}
        style={autoHeight ? { height: 'auto' } : undefined}
        subtitle={description}
        button={
          !noActionButton
            ? {
                label: t(actionButtonTxtKey),
                onClick: onButtonClick
              }
            : undefined
        }
      >
        <SectionWrapper loading={loading} error={error} section={section}>
          {children}
        </SectionWrapper>
      </Card>
    </>
  );
}
