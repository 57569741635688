import { PaletteType } from '@groupbuilderoy/gb-components-library';
import { AppContext } from 'context/App/AppContext';
import { ThemeFonts } from 'context/App/types';
import { useContext, useEffect, useState } from 'react';

export default function useGetCustomizationSettings() {
  const [parsedSettings, setParsedSettings] = useState<PaletteType>();
  const [assets, setAssets] = useState<{
    logo: string;
    headerImg: string;
    backgroundImg: string;
  }>();
  const [fonts, setFonts] = useState<ThemeFonts>();
  const { appState } = useContext(AppContext);
  const customizationSettings = appState.tenantSettings?.themeCustomizationSettings;

  useEffect(() => {
    if (customizationSettings) {
      setParsedSettings({
        primary: customizationSettings?.palette?.main || '#B7835F',
        secondary: customizationSettings?.palette?.secondary || '#EFEEE4',
        navBarPrimary: customizationSettings?.palette?.navigationBar || '#05061B',
        navBarSecondary: customizationSettings?.palette?.navigationBarText || '#F2F4F7',
        sectionsHeader: customizationSettings.palette?.sectionHeaderText || '#000000de',
        backgroundColor: customizationSettings.palette?.backgroundColor || '#fafafa'
      });
      setAssets(customizationSettings.assets);
      setFonts(customizationSettings.fonts);
    }
  }, [customizationSettings]);

  return { parsedSettings, assets, fonts };
}
