import SectionForm from 'components/SectionForm/SectionForm';
import { ISection } from 'components/Sections/Section';
import useApi from 'hooks/useApi';
import { useRouteMatch } from 'react-router-dom';

export default function CreateHeaderSection() {
  const api = useApi();
  const { url } = useRouteMatch();
  const urlSegments = url.split('/');
  const parentSectionId = Number(urlSegments[urlSegments.length - 2]);
  return (
    <SectionForm
      onSubmit={(section: ISection) => {
        delete section.id;
        const newSection = isNaN(parentSectionId)
          ? section
          : { ...section, parentSectionId: parentSectionId };
        return api.createSection(newSection).then((response) => {
          if (response.id) {
            api.updateSectionOrder(response.id, -1);
          }
          return response;
        });
      }}
    />
  );
}
