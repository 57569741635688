import { createStyles, makeStyles } from '@material-ui/core/styles';
export default makeStyles(() =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1rem 0'
    },
    leftButtons: {
      display: 'flex',
      gap: '1rem'
    },
    rightButtons: {
      marginLeft: 'auto',
      display: 'flex',
      gap: '1rem'
    },

    buttonLeft: {
      marginLeft: '0'
    }
  })
);
