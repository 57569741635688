import { getProjectPhases } from 'axiosInstances';
import { AppContext } from 'context/App/AppContext';
import { PhaseContext } from 'context/Phase/PhaseContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import useT from './useT';

const useSetupPhases = () => {
  const { appState } = useContext(AppContext);
  const { phaseState, dispatch } = useContext(PhaseContext);
  const { projectState } = useContext(ProjectContext);
  const { selectedProject } = projectState;
  const params = useParams<{ phaseId?: string }>();
  const t = useT();

  const fetch = async () => {
    if (!selectedProject) return;

    try {
      const { data } = await getProjectPhases({
        projectId: String(selectedProject.id),
        gbToken: String(appState.token)
      });

      if (!data || !data.data) throw new Error('Failed to get phases');

      dispatch({
        ...phaseState,
        phases: data.data,
        selectedPhase:
          phaseState.selectedPhase ||
          data.data.find((phase) => String(phase.id) === params.phaseId) ||
          data.data[0] ||
          null
      });
    } catch (e) {
      console.error(e);
      dispatch({
        ...phaseState,
        error: t('alert.phasesFetchFailed')
      });
    }
  };

  useEffect(() => {
    fetch();
  }, [projectState.selectedProject?.id]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useSetupPhases;
