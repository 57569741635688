import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = ({
  backgroundColor,
  backgroundImg
}: {
  backgroundColor?: string;
  backgroundImg?: string;
}) => {
  return makeStyles((theme: Theme) =>
    createStyles({
      body: {
        background: backgroundImg ? `url(${backgroundImg})` : 'none'
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
      },
      container: {
        margin: '2rem auto'
      },
      iframeContainer: {
        margin: 0,
        padding: 0,
        maxWidth: '100%',
        width: '100%',
        minHeight: '100%',
        height: 'calc(100vh - 72px - 3rem)'
      },
      selectionsFrameContainer: {
        margin: 0,
        padding: 0,
        maxWidth: '100%',
        width: '100%',
        minHeight: '100%',
        height: 'calc(100vh - 72px)',
        background: backgroundColor || '#fafafa',
        backgroundImage: backgroundImg ? `url(${backgroundImg})` : 'none'
      },
      alert: {
        margin: '20px'
      }
    })
  );
};
